import filterIcon from '../../../../../assets/icons/filter.svg'

import './styles.css'

interface NumbersBoxProps {
  badgeColor: string
  badgeIcon: string
  info: string | number
  title: string
  filter?: (classificationsType: 'input' | 'output') => void
}

export default function NumbersBox({ badgeColor, badgeIcon, filter, info, title }: NumbersBoxProps) {
  return (
    <div className="numbers__box">
      {filter ? (
        <div className="filter__button">
          <img src={filterIcon} alt="" />
        </div>
      ) : null}

      <div className="right__icon__box" style={{ backgroundColor: badgeColor }}>
        <img src={require(`../../../../../assets/icons/${badgeIcon}.svg`)} alt="" />
      </div>

      <h1>{info}</h1>

      <div className="stats__box__footer">
        <h3>{title}</h3>
      </div>
    </div>
  )
}
