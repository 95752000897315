import siloVirtualImage from '../../assets/images/silo-virtual.png'
import checkedIcon from '../../assets/icons/checked.svg'

import './styles.css'

export default function EmailSended() {
  return (
    <div className="email__sended">
      <div className="container">
        <img src={siloVirtualImage} alt="silo-virtual" className="logo__app" />

        <div className="confirmed">
          <img src={checkedIcon} alt="check-icon" />

          <h5>E-MAIL ENVIADO</h5>
        </div>
      </div>
    </div>
  )
}
