import { useState } from 'react'
import { apiAuth } from '../../services/apiAuth'
import { validateCNPJ, validateCPF } from '../../helpers/validators'

import siloVirtualImage from '../../assets/images/silo-virtual.png'
import userIcon from '../../assets/icons/user.svg'
import infoIcon from '../../assets/icons/info.svg'
import lockIcon from '../../assets/icons/lock.svg'
import emailIcon from '../../assets/icons/email.svg'
import arrowLeftIcon from '../../assets/icons/arrow-left.svg'
import Toast from '../../components/Toast'

import './styles.css'

interface SubmitType {
  razao_social: string
  cpf_cnpj: string
  email: string
  senha: string
  confirmar_senha?: string
}

interface InputType {
  value: string
  name: string
}

export default function Signup() {
  const [formValues, setFormValues] = useState({} as SubmitType)
  const [waitingRequest, setWaitingRequest] = useState(false)
  const [toastType, setToastType] = useState<'error' | 'alert'>('error')
  const [toastVisible, setToastVisible] = useState<boolean>(false)
  const [toastMessage, setToastMessage] = useState<string>('')

  // const cnpjPattern = '([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})'
  // const cnpjMask = '99.999.999/9999-99'
  // const cpfPattern = '([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})'
  // const cpfMask = '999.999.999-99'

  function handleSubmit(e: any) {
    e.preventDefault()
    createUser()
  }

  function handleInputChange(input: InputType) {
    let registro = ''

    if (input.name === 'REGISTRO') {
      registro = input.value.replace(/[^\d]+/g, '')

      if (!registro) {
        setFormValues((prevValues) => ({
          ...prevValues,
          REGISTRO: '',
        }))
        return
      } else {
        setFormValues((prevValues) => ({
          ...prevValues,
          REGISTRO: registro,
        }))
        return
      }
    }

    setFormValues((prevValues) => ({
      ...prevValues,
      [input.name]: input.value,
    }))
  }

  async function createUser() {
    let cpfCnpjFormated = formValues.cpf_cnpj.replace(/[^\d]+/g, '')
    const isValidRegister = cpfCnpjFormated.length > 11 ? validateCNPJ(cpfCnpjFormated) : validateCPF(cpfCnpjFormated)

    if (!isValidRegister) {
      setToastType('alert')
      setToastMessage(`CPF/CNPJ INVÁLIDO`)
      setToastVisible(true)
      setTimeout(() => {
        setToastVisible(false)
      }, 5000)
      return
    }

    setWaitingRequest(true)

    await apiAuth
      .post('/usuarios', formValues)
      .then((response) => {
        window.location.href = '/account-created'
      })
      .catch((uncaught) => {
        const { error } = uncaught.response.data

        if (error) {
          setToastType('error')
          setToastMessage(error)
          setToastVisible(true)
          setTimeout(() => {
            setToastVisible(false)
          }, 5000)
          setWaitingRequest(false)
          return
        }
      })
      .finally(() => setWaitingRequest(false))
  }

  return (
    <div className="signup">
      <form onSubmit={(e) => handleSubmit(e)}>
        <img src={siloVirtualImage} alt="silo-virtual" className="logo__app" />

        <div className="row">
          <div className="input__box">
            <input
              type="text"
              required
              name="razao_social"
              placeholder="RAZÃO SOCIAL"
              onChange={(e) =>
                handleInputChange({
                  name: 'razao_social',
                  value: e.target.value,
                })
              }
            />
            <img src={infoIcon} alt="info-icon" />
          </div>

          <div className="input__box">
            <input
              type="text"
              required
              name="cpf_cnpj"
              placeholder="CPF/CNPJ"
              onChange={(e) =>
                handleInputChange({
                  name: 'cpf_cnpj',
                  value: e.target.value,
                })
              }
            />
            <img src={userIcon} alt="user-icon" />
          </div>
        </div>

        <div className="input__box">
          <input
            type="email"
            required
            name="email"
            autoComplete="off"
            placeholder="E-MAIL"
            onChange={(e) =>
              handleInputChange({
                name: 'email',
                value: e.target.value,
              })
            }
          />
          <img src={emailIcon} alt="email-icon" />
        </div>

        <div className="row">
          <div className="input__box">
            <input
              type="password"
              required
              placeholder="SENHA"
              onChange={(e) =>
                handleInputChange({
                  name: 'senha',
                  value: e.target.value,
                })
              }
            />
            <img src={lockIcon} alt="user-icon" />
          </div>

          <div className="input__box">
            <input
              type="password"
              required
              placeholder="CONFIRMAR SENHA"
              onChange={(e) =>
                handleInputChange({
                  name: 'confirmar_senha',
                  value: e.target.value,
                })
              }
            />
            <img src={lockIcon} alt="user-icon" />
          </div>
        </div>

        <Toast visible={toastVisible} type={toastType} title={toastMessage} />

        <div className="buttons">
          <a href="/">
            <img src={arrowLeftIcon} alt="arrow-left" />
            JÁ TENHO UMA CONTA
          </a>

          <button disabled={waitingRequest} className={waitingRequest ? 'button__loading' : ''} type="submit">
            <span>CRIAR CONTA</span>
          </button>
        </div>
      </form>
    </div>
  )
}
