import { useContext, useEffect, useState } from 'react'

import './styles.css'

import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import { Popper, TextField } from '@mui/material'
import { FilterProps } from '../../pages/warehouse/dryer'
import { styled } from '@mui/material/styles'
import { CacheContext } from '../../contexts/CacheContext'
import { Harvest, Product, Silo } from '../../interfaces'
import { today } from '../../utils/common'

import close from '../../assets/icons/close.svg'

interface InitialClassificationsFilterParamsModalProps {
  setInitialClassificationsFilterParamsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  filter: (props: FilterProps) => void
}

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    // backgroundColor: '#fff',
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
    '& li': {
      fontFamily: 'IBM Plex Sans Bold',
    },
  },
})

export default function InitialClassificationsFilterParamsModal({
  setInitialClassificationsFilterParamsModalOpen,
  filter,
}: InitialClassificationsFilterParamsModalProps) {
  const { loadProductsFromCache, loadHarvestsFromCache, loadSilosFromCache } = useContext(CacheContext)

  const cachedSilos = loadSilosFromCache()
  const persistProduct = localStorage.getItem('@SILO:persistProduct')

  const [filterProps, setFilterProps] = useState<FilterProps>({
    harvestId: loadHarvestsFromCache().find((harvest) => harvest.padrao === 1)?.id,
    siloId: cachedSilos.length === 1 ? cachedSilos[0].id : undefined,
    startDate: today,
    finishDate: today,
  } as FilterProps)

  function callFilter(e: any) {
    e.preventDefault()
    filter(filterProps)
    setInitialClassificationsFilterParamsModalOpen(false)
  }

  useEffect(() => {
    if (persistProduct) {
      setFilterProps((prev) => ({ ...prev, productId: Number(JSON.parse(persistProduct).id) }))
    }
  }, [])

  return (
    <div className="filter__classifications__modal">
      <div className="filter__classifications__modal__box">
        <div className="header">
          <h1>PARÂMETROS INICIAIS</h1>

          <img src={close} alt="close" onClick={() => setInitialClassificationsFilterParamsModalOpen(false)} />
        </div>

        <form onSubmit={(e) => callFilter(e)}>
          <Autocomplete
            fullWidth
            isOptionEqualToValue={(option, value) => option.id === value.id}
            id="harvest"
            selectOnFocus={false}
            PopperComponent={StyledPopper}
            options={loadHarvestsFromCache()}
            defaultValue={loadHarvestsFromCache().find((harvest) => harvest.padrao === 1)}
            getOptionLabel={(option) => option.safra}
            onChange={(event, value: Harvest | null, _) => {
              if (value) {
                setFilterProps((prev) => ({ ...prev, harvestId: value.id }))
              }
            }}
            sx={{
              '& input': {
                fontFamily: 'IBM Plex Sans Bold',
                color: '#a3a3a3',
              },
              '& fieldset': { borderColor: '#a3a3a3' },
              '& label': { color: '#a3a3a3' },
            }}
            renderInput={(params) => (
              <TextField
                sx={{
                  '& label': {
                    fontFamily: 'IBM Plex Sans Bold',
                  },
                }}
                {...params}
                label="SAFRA"
                required
              />
            )}
          />

          <Autocomplete
            fullWidth
            isOptionEqualToValue={(option, value) => option.id === value.id}
            id="product"
            selectOnFocus={false}
            PopperComponent={StyledPopper}
            options={loadProductsFromCache()}
            getOptionLabel={(option) => option.produto}
            defaultValue={
              persistProduct
                ? loadProductsFromCache().find((product) => product.id === Number(JSON.parse(persistProduct).id))
                : null
            }
            onChange={(event, value: Product | null, _) => {
              if (value) {
                localStorage.setItem('@SILO:persistProduct', JSON.stringify(value))
                setFilterProps((prev) => ({ ...prev, productId: value.id }))
              }
            }}
            sx={{
              '& input': {
                fontFamily: 'IBM Plex Sans Bold',
                color: '#a3a3a3',
              },
              '& fieldset': { borderColor: '#a3a3a3' },
              '& label': { color: '#a3a3a3' },
            }}
            renderInput={(params) => (
              <TextField
                sx={{
                  '& label': {
                    fontFamily: 'IBM Plex Sans Bold',
                  },
                }}
                {...params}
                label="PRODUTO"
                required
              />
            )}
          />

          <Autocomplete
            fullWidth
            isOptionEqualToValue={(option, value) => option.id === value.id}
            id="silo"
            selectOnFocus={false}
            PopperComponent={StyledPopper}
            options={cachedSilos}
            getOptionLabel={(option) => option.silo}
            defaultValue={filterProps.siloId ? cachedSilos.find((silo) => silo.id === filterProps.siloId) : null}
            onChange={(event, value: Silo | null, _) => {
              if (value) {
                setFilterProps((prev) => ({ ...prev, siloId: value.id }))
              }
            }}
            sx={{
              '& input': {
                fontFamily: 'IBM Plex Sans Bold',
                color: '#a3a3a3',
              },
              '& fieldset': { borderColor: '#a3a3a3' },
              '& label': { color: '#a3a3a3' },
            }}
            renderInput={(params) => (
              <TextField
                sx={{
                  '& label': {
                    fontFamily: 'IBM Plex Sans Bold',
                  },
                }}
                {...params}
                focused={!filterProps.siloId}
                placeholder={'SELECIONE UM SILO'}
                label="SILO"
                required
              />
            )}
          />

          <div className="buttons">
            <button className="confirm" type="submit">
              <span>CONFIRMAR</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
