import { useContext, useEffect, useState } from 'react'

import './styles.css'

import { apiAuth } from '../../services/apiAuth'
import { Modal } from '@mui/material'
import { AuthContext } from '../../contexts/AuthContext'

import close from '../../assets/icons/close.svg'
import DeviceDetails from '../DeviceDetails'

interface ConnectedDevicesModalProps {
  client: Client | undefined
  getClients: any
  closeModal: any
}

interface Client {
  ID: number
  NOME: string
  REGISTRO: string
  LIBERADO: number
  COD_PRODUTOR: number
}

interface DbDevice {
  ID: number
  COD_PRODUTOR: number
  DISPOSITIVO: DeviceProps
  LIBERADO: number
  DATA: string
  HORA: string
  DEVICE_NAME: string
  DATA_CADASTRO: Date
  DATA_ATUALIZACAO: Date
}

export interface DeviceProps {
  nome: string
  Versao: string
  name: string
  marca: string
  modelo: string
  fabricante: string
  buildid: string
  InternalBuildId: string
  MacRede: string
  Endereco: string
}

export default function ConnectedDevices({ client, getClients, closeModal }: ConnectedDevicesModalProps) {
  const { getToken } = useContext(AuthContext)

  const [modalDeviceDetailsOpened, setModalDeviceDetailsOpened] = useState(false)
  const [devices, setDevices] = useState<DbDevice[]>([])
  const [deviceToOpen, setDeviceToOpen] = useState<DbDevice>()
  const [clientLiberated, setClientLiberated] = useState<boolean>(!!client?.LIBERADO)

  useEffect(() => {
    if (!!getToken()) {
      getClientDevices()
    }
  }, [])

  async function getClientDevices() {
    const response = await apiAuth.get(`/dispositivos/produtor/${client?.COD_PRODUTOR}`)
    setDevices(response.data)
  }

  async function handleClientLiberation() {
    setClientLiberated((prevState) => !prevState)
    await apiAuth.patch(`/produtores/${client?.ID}/${clientLiberated ? 'bloquear' : 'liberar'}`)
    getClients()
  }

  return (
    <div className="connected__devices__modal">
      <div className="connected__devices__box">
        <div className="header">
          <div>
            <div className="liberated">
              <h5>CLIENTE LIBERADO</h5>

              <label className="switch">
                <input type="checkbox" defaultChecked={clientLiberated} onChange={() => handleClientLiberation()} />
                <span className="slider round"></span>
              </label>
            </div>
            <h1>DISPOSITIVOS CONECTADOS</h1>
          </div>

          <img src={close} alt="close" onClick={() => closeModal(false)} />
        </div>

        <div className="overflow">
          <table>
            <thead>
              <tr>
                <th align="left">DATA/HORA</th>
                <th align="left">DISPOSITIVO</th>
                <th align="right">STATUS</th>
              </tr>
            </thead>

            <tbody>
              {devices.map((device) => (
                <tr
                  key={device.ID}
                  onClick={() => {
                    setDeviceToOpen(device)
                    setModalDeviceDetailsOpened(true)
                  }}
                >
                  <td>
                    {device.DATA.split('T')[0].split('-')[2]}/{device.DATA.split('T')[0].split('-')[1]}/
                    {device.DATA.split('T')[0].split('-')[0]} {device.HORA}
                  </td>
                  <td>{device.DEVICE_NAME}</td>
                  <td>{device.LIBERADO ? 'Liberado' : 'Pendente'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Modal onClose={setModalDeviceDetailsOpened} aria-labelledby="device-details" open={modalDeviceDetailsOpened}>
          <DeviceDetails
            device={deviceToOpen}
            getClientDevices={getClientDevices}
            closeModal={setModalDeviceDetailsOpened}
          />
        </Modal>
      </div>
    </div>
  )
}
