import { useContext, useEffect, useState } from 'react'

import './styles.css'

import Cookies from 'js-cookie'

import { Autocomplete, autocompleteClasses, CircularProgress, Popper, TextField, Tooltip } from '@mui/material'
import { createTheme, styled, ThemeProvider } from '@mui/material/styles'
import { CacheContext } from '../../contexts/CacheContext'
import { Classification, Harvest, Product } from '../../interfaces'
import { apiEsteiras } from '../../services/apiEsteiras'
import { today } from '../../utils/common'
import { Link } from 'react-router-dom'
import { error } from '../../utils/error'

import Header from '../../components/Header'
import Sidebar from '../../components/Sidebar'
import filterIcon from '../../assets/icons/filter.svg'
import fireIcon from '../../assets/icons/sidebar/fire.svg'
import eyeIcon from '../../assets/icons/eye-white.svg'
import alertIcon from '../../assets/icons/alert-yellow.svg'

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
    '& li': {
      fontFamily: 'IBM Plex Sans Bold',
    },
  },
})

const theme = createTheme({
  palette: {
    secondary: {
      main: '#FFF',
    },
  },
})

export default function Dashboard() {
  const { loadProductsFromCache, loadHarvestsFromCache, loadCache } = useContext(CacheContext)

  const company = Cookies.get('@SILO:company')
  const harvests = loadHarvestsFromCache()
  const products = loadProductsFromCache()

  const [preloading, setPreloading] = useState(true)

  const [filterHarvest, setFilterHarvest] = useState<number | null>(harvests.find((h) => h.padrao === 1)?.id || null)
  const [filterProduct, setFilterProduct] = useState<number | null>(products[0]?.id || null)

  const [lastEntryClassifications, setLastEntryClassifications] = useState<Classification[]>([])
  const [lastOutputClassifications, setLastOutputClassifications] = useState<Classification[]>([])

  useEffect(() => {
    new Promise(async (res, rej) => {
      await loadCache()
      await loadLastClassifications()

      res(null)
    }).finally(() => {
      setTimeout(() => {
        setPreloading(false)
      }, 800)
    })
  }, [])

  async function loadLastClassifications(): Promise<void> {
    try {
      const { data } = await apiEsteiras.get('/classificacoes/ultimas', {
        params: { id_produto: filterProduct, id_safra: filterHarvest, data: today.split('/').reverse().join('-') },
      })
      setLastEntryClassifications(data.filter((d: Classification) => d.tipo === 1))
      setLastOutputClassifications(data.filter((d: Classification) => d.tipo === 2))
    } catch (err) {
      error('fail on load last classifications', { err })
    }
  }

  async function filter() {
    await loadLastClassifications()
  }

  return (
    <div className="dashboard">
      <Header />

      <div className="content">
        <Sidebar />

        {preloading ? (
          <div className="preloading">
            <ThemeProvider theme={theme}>
              <CircularProgress color="secondary" />
            </ThemeProvider>
          </div>
        ) : (
          <div className="container">
            <div className="welcome">
              <h4>Dashboard</h4>
              <h1>{company}</h1>
            </div>

            <div className="page__header">
              <div className="filter">
                <div className="inputs">
                  <Autocomplete
                    fullWidth
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    id="harvest"
                    selectOnFocus={false}
                    PopperComponent={StyledPopper}
                    options={loadHarvestsFromCache()}
                    defaultValue={loadHarvestsFromCache().find((harvest) => harvest.padrao === 1)}
                    getOptionLabel={(option) => option.safra}
                    onChange={(event, value: Harvest | null, _) => {
                      if (value) {
                        setFilterHarvest(value.id)
                      }
                    }}
                    sx={{
                      '& input': {
                        fontFamily: 'IBM Plex Sans Bold',
                        color: '#a3a3a3',
                      },
                      '& fieldset': { borderColor: '#a3a3a3' },
                      '& label': { color: '#a3a3a3' },
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          '& label': {
                            fontFamily: 'IBM Plex Sans Bold',
                          },
                        }}
                        {...params}
                        label="SAFRA"
                        required
                      />
                    )}
                  />

                  <Autocomplete
                    fullWidth
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    id="product"
                    selectOnFocus={false}
                    PopperComponent={StyledPopper}
                    options={loadProductsFromCache()}
                    defaultValue={loadProductsFromCache()[0]}
                    getOptionLabel={(option) => option.produto}
                    onChange={(event, value: Product | null, _) => {
                      if (value) {
                        setFilterProduct(value.id)
                      }
                    }}
                    sx={{
                      '& input': {
                        fontFamily: 'IBM Plex Sans Bold',
                        color: '#a3a3a3',
                      },
                      '& fieldset': { borderColor: '#a3a3a3' },
                      '& label': { color: '#a3a3a3' },
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          '& label': {
                            fontFamily: 'IBM Plex Sans Bold',
                          },
                        }}
                        {...params}
                        label="PRODUTO"
                        required
                      />
                    )}
                  />

                  <Tooltip title="Filtrar" placement="top">
                    <div className="filter__button" onClick={() => filter()}>
                      <img src={filterIcon} alt="filter" />
                    </div>
                  </Tooltip>
                </div>

                <div className="selected__params">
                  <h3>
                    <b>SAFRA: </b>
                    {harvests.find((h) => h.id === filterHarvest)?.safra}
                  </h3>
                  <h3>
                    <b>PRODUTO: </b>
                    {products.find((p) => p.id === filterProduct)?.produto}
                  </h3>
                </div>
              </div>

              {/* <div className="warehouse__balance">
                <div className="title__with__line">
                  <h5>SALDO ATUAL</h5>
                  <hr />
                </div>
                <div className="box">
                  <h1>SOJA</h1>
                  <div className="values">
                    <h3>12.042.552 KG</h3>
                    <h4>200.709 SC</h4>
                  </div>
                </div>
              </div> */}
            </div>

            <div className="section">
              <div className="title__with__line">
                <h5>
                  <img src={fireIcon} alt="" />
                  SECAGEM
                </h5>
                <hr />
              </div>

              <div className="row">
                <div
                  className="last__classifications"
                  style={{ rowGap: lastEntryClassifications.length ? '1rem' : '3.5rem' }}
                >
                  <div className="last__classifications__header">
                    <h5>últimas entradas (hoje)</h5>

                    <div className="last__classifications__header__buttons">
                      {lastEntryClassifications.length > 0 ? null : (
                        <Tooltip title="Nenhuma classificação encontrada." placement="top">
                          <img src={alertIcon} alt="alert" className="alert__icon" />
                        </Tooltip>
                      )}

                      <Link to="/dryer">
                        <Tooltip title="Ver mais" placement="top">
                          <div className="details__button">
                            <img src={eyeIcon} alt="filter" />
                          </div>
                        </Tooltip>
                      </Link>
                    </div>
                  </div>

                  {lastEntryClassifications.length > 0 ? (
                    <table id="table">
                      <thead>
                        <tr>
                          <th align="left">HORA</th>
                          <th align="left">PRODUTO</th>
                          <th align="left">SILO</th>
                          <th align="right">TEMP.</th>
                          <th align="right">UMIDADE</th>
                        </tr>
                      </thead>

                      <tbody>
                        {lastEntryClassifications.map((c) => (
                          <tr key={c.id}>
                            <td>{c.hora}</td>
                            <td>{c.produto}</td>
                            <td>{c.silo}</td>
                            <td align="right">{c.temperatura}ºC</td>
                            <td align="right">{c.umidade}%</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="empty__data">
                      <h4>NENHUMA CLASSIFICAÇÃO FEITA HOJE</h4>
                    </div>
                  )}
                </div>

                <div
                  className="last__classifications"
                  style={{ rowGap: lastOutputClassifications.length ? '1rem' : '3.5rem' }}
                >
                  <div className="last__classifications__header">
                    <h5>últimas saídas (hoje)</h5>

                    <div className="last__classifications__header__buttons">
                      {lastOutputClassifications.length > 0 ? null : (
                        <Tooltip title="Nenhuma classificação encontrada." placement="top">
                          <img src={alertIcon} alt="alert" className="alert__icon" />
                        </Tooltip>
                      )}
                      <Link to="/dryer">
                        <Tooltip title="Ver mais" placement="top">
                          <div className="details__button">
                            <img src={eyeIcon} alt="filter" />
                          </div>
                        </Tooltip>
                      </Link>
                    </div>
                  </div>

                  {lastOutputClassifications.length > 0 ? (
                    <table id="table">
                      <thead>
                        <tr>
                          <th align="left">HORA</th>
                          <th align="left">PRODUTO</th>
                          <th align="left">SILO</th>
                          <th align="right">TEMP.</th>
                          <th align="right">UMIDADE</th>
                        </tr>
                      </thead>

                      <tbody>
                        {lastOutputClassifications.map((c) => (
                          <tr key={c.id}>
                            <td>{c.hora}</td>
                            <td>{c.produto}</td>
                            <td>{c.silo}</td>
                            <td align="right">{c.temperatura}ºC</td>
                            <td align="right">{c.umidade}%</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="empty__data">
                      <h4>NENHUMA CLASSIFICAÇÃO FEITA HOJE</h4>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
