import { createContext } from 'react'
import { useState } from 'react'

interface SidebarContextType {
  opened: boolean
  handleOpen: () => void
}

export const SidebarContext = createContext({} as SidebarContextType)

export function SidebarProvider({ children }: any) {
  const [opened, setOpened] = useState(false)

  function handleOpen() {
    setOpened((prev) => !prev)
  }

  return <SidebarContext.Provider value={{ opened, handleOpen }}>{children}</SidebarContext.Provider>
}
