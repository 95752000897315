import { useState } from 'react'
import { api } from '../../services/api'

import siloVirtualImage from '../../assets/images/silo-virtual.png'
import arrowLeftIcon from '../../assets/icons/arrow-left.svg'
import alert from '../../assets/icons/alert.svg'
import Toast from '../../components/Toast'

import './styles.css'

export default function Unverified() {
  const email = window.localStorage.getItem('@EMAIL')

  const [waitingAPIResponse, setWaitingAPIResponse] = useState(false)

  const [toastType, setToastType] = useState<'error' | 'alert'>('error')
  const [toastVisible, setToastVisible] = useState<boolean>(false)
  const [toastMessage, setToastMessage] = useState<string>('')

  async function resendConfirmationEmail() {
    setWaitingAPIResponse(true)

    await api
      .post('/usuarios/email/reenviar', { email })
      .then(() => {
        setWaitingAPIResponse(false)
        window.location.href = '/email-sended'
      })
      .catch((uncaught) => {
        setToastType('error')
        setToastMessage(uncaught.data.message)
        setToastVisible(true)
        setTimeout(() => {
          setToastVisible(false)
        }, 5000)
      })
  }

  return (
    <div className="unverified__page">
      <div className="container">
        <img src={siloVirtualImage} alt="silo-virtual" className="logo__app" />

        <div className="unverified">
          <img src={alert} alt="check-icon" />

          <h5>CONFIRME SEU E-MAIL</h5>
        </div>

        <h1>NÃO RECEBEU UM E-MAIL DE CONFIRMAÇÃO?</h1>

        {waitingAPIResponse ? (
          <div className="loading"></div>
        ) : (
          <h4 onClick={() => resendConfirmationEmail()}>REENVIAR E-MAIL</h4>
        )}

        <Toast visible={toastVisible} type={toastType} title={toastMessage} />

        <a href="/">
          <img src={arrowLeftIcon} alt="arrow-left" />
          VOLTAR PARA O LOGIN
        </a>
      </div>
    </div>
  )
}
