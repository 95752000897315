import { createContext, useContext, useEffect } from 'react'
import { useState } from 'react'
import { Pending } from '../pages/devices'
import { AuthContext } from './AuthContext'
import { apiAuth } from '../services/apiAuth'

interface TotalizersContextType {
  totalActives: number
  totalPendings: number
  totalNewAccesses: number
  pendings: Pending[]
  getNewAccessTotalizers: () => void
  getActiveTotalizers: () => void
  getPendingTotalizers: () => void
}

export const TotalizersContext = createContext({} as TotalizersContextType)

export function TotalizersProvider({ children }: any) {
  const [totalActives, setTotalActives] = useState(0)
  const [totalPendings, setTotalPendings] = useState(0)
  const [totalNewAccesses, setTotalNewAccesses] = useState(0)
  const [pendings, setPendings] = useState<Pending[]>([])

  const { getToken } = useContext(AuthContext)

  useEffect(() => {
    if (!!getToken()) {
      getPendingTotalizers()
      getActiveTotalizers()
      getNewAccessTotalizers()
    }
  }, [])

  async function getPendingTotalizers() {
    await apiAuth
      .get(`/dispositivos/pendentes`)
      .then((response) => {
        let total = 0
        response.data.map((pendent: { pendentes: number }) => (total += pendent.pendentes))
        setTotalPendings(total)
        setPendings(response.data)
        window.localStorage.setItem('PENDENTES', String(total))
      })
      .catch((uncaught) => {
        return
      })
  }

  async function getActiveTotalizers() {
    await apiAuth
      .get(`/dispositivos/ativos`)
      .then((response) => {
        let total = 0
        response.data.map((active: { ativos: number }) => (total += active.ativos))
        setTotalActives(total)
      })
      .catch((uncaught) => {
        return
      })
  }

  async function getNewAccessTotalizers() {
    await apiAuth
      .get(`/dispositivos/novos`)
      .then((response) => {
        let total = 0
        response.data.map((access: { acessos: number }) => (total += access.acessos))
        setTotalNewAccesses(total)
      })
      .catch((uncaught) => {
        return
      })
  }

  return (
    <TotalizersContext.Provider
      value={{
        totalActives,
        totalPendings,
        totalNewAccesses,
        pendings,
        getNewAccessTotalizers,
        getActiveTotalizers,
        getPendingTotalizers,
      }}
    >
      {children}
    </TotalizersContext.Provider>
  )
}
