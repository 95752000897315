import { BrowserRouter, Routes, Route } from 'react-router-dom'

import App from './App'
import Signup from './pages/signup'
import Dashboard from './pages/dashboard'
import Devices from './pages/devices'
import AccountCreated from './pages/account-created'
import ConfirmedAccount from './pages/confirmed-account'
import ForgotPassword from './pages/forgot-password'
import RecoverPassword from './pages/recover-password'
import RecoveredPassword from './pages/recovered-password'
import EmailSended from './pages/email-sended'
import Unverified from './pages/unverified'
import Dryer from './pages/warehouse/dryer'
import EmployeesAccounts from './pages/employees-accounts'

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/signup/confirm" element={<ConfirmedAccount />} />
        <Route path="/account-created" element={<AccountCreated />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/recover-password" element={<RecoverPassword />} />
        <Route path="/recovered-password" element={<RecoveredPassword />} />
        <Route path="/email-sended" element={<EmailSended />} />
        <Route path="/unverified" element={<Unverified />} />
        <Route path="/devices" element={<Devices />} />
        <Route path="/employees-accounts" element={<EmployeesAccounts />} />
        <Route path="/dryer" element={<Dryer />} />
      </Routes>
    </BrowserRouter>
  )
}
