const actualDay = new Date().getDate().toString().padStart(2, '0')
const actualMonth = (new Date().getMonth() + 1).toString().padStart(2, '0')
const actualYear = new Date().getFullYear()
const today = `${actualDay}/${actualMonth}/${actualYear}`

const nowHour = () => {
  return new Date().toLocaleTimeString()
}

export { actualDay, actualMonth, actualYear, today, nowHour }
