import './styles.css'

import { Classification } from '../../interfaces'

interface TableRowProps {
  item: Classification
}

export default function TableRow({ item }: TableRowProps) {
  return (
    <tr className="table__row">
      <td>
        {item.data.split('-').reverse().join('/')} {item.hora}
      </td>
      <td>{item.temperatura} ºC</td>
      <td>{item.umidade}%</td>
      <td>{item.silo}</td>
      <td>{item.produto}</td>
      <td>{item.safra}</td>
      <td>{item.usuario}</td>
    </tr>
  )
}
