import siloVirtualImage from '../../assets/images/silo-virtual.png'
import checkedIcon from '../../assets/icons/checked.svg'
import arrowRightIcon from '../../assets/icons/arrow-right.svg'

import './styles.css'

export default function RecoveredPassword() {
  return (
    <div className="recovered__password">
      <div className="container">
        <img src={siloVirtualImage} alt="silo-virtual" className="logo__app" />

        <div className="confirmed">
          <img src={checkedIcon} alt="check-icon" />

          <h5>SENHA ALTERADA</h5>
        </div>

        <a href="/">
          FAZER LOGIN
          <img src={arrowRightIcon} alt="arrow-left" />
        </a>
      </div>
    </div>
  )
}
