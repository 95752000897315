import React from 'react'
import ReactDOM from 'react-dom/client'
import Router from './router'

import { AuthProvider } from './contexts/AuthContext'
import { TotalizersProvider } from './contexts/TotalizersContext'
import { SidebarProvider } from './contexts/SidebarContext'
import { CacheProvider } from './contexts/CacheContext'

import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <AuthProvider>
    <CacheProvider>
      <TotalizersProvider>
        <SidebarProvider>
          <Router />
        </SidebarProvider>
      </TotalizersProvider>
    </CacheProvider>
  </AuthProvider>
)
