import { BaseSyntheticEvent, useState } from 'react'

import './styles.css'

import { validateCNPJ, validateCPF } from '../../helpers/validators'
import { api } from '../../services/api'

import close from '../../assets/icons/close.svg'
import userIcon from '../../assets/icons/user.svg'
import infoIcon from '../../assets/icons/info.svg'
import Toast from '../Toast'
import ReactInputMask from 'react-input-mask'

interface CreateNewProducerModalProps {
  closeModal: any
  getClients: () => Promise<void>
}

export default function CreateNewProducer({ closeModal, getClients }: CreateNewProducerModalProps) {
  const [name, setName] = useState('')
  const [register, setRegister] = useState('')
  const [waitingRequest, setWaitingRequest] = useState(false)
  const [toastType, setToastType] = useState<'error' | 'alert' | 'success'>('alert')
  const [toastVisible, setToastVisible] = useState<boolean>(false)
  const [toastMessage, setToastMessage] = useState<string>('')

  function handleToast(type: 'error' | 'alert' | 'success', visible: boolean, message: string) {
    setToastType(type)
    setToastMessage(message)
    setToastVisible(visible)

    setTimeout(() => {
      setToastVisible(false)
    }, 5000)
  }

  async function handleSubmit(e: BaseSyntheticEvent) {
    e.preventDefault()
    setWaitingRequest(true)

    let isValidRegister: boolean = false

    if (register.length > 11) {
      isValidRegister = validateCNPJ(register)
    } else {
      isValidRegister = validateCPF(register)
    }

    if (!isValidRegister) {
      setWaitingRequest(false)

      setToastType('alert')
      setToastMessage(`CNPJ/CPF INVÁLIDO`)
      setToastVisible(true)

      setTimeout(() => {
        setToastVisible(false)
      }, 5000)

      return
    }

    const response = await api
      .post('/produtores', { NOME: name, REGISTRO: register })
      .finally(() => setWaitingRequest(false))

    if (response.status !== 200) {
      handleToast('error', true, response.data.error)
      return
    }

    getClients()
    handleToast('success', true, 'PRODUTOR CADASTRADO')

    setTimeout(() => {
      closeModal(false)
    }, 4000)
  }

  function handleRegisterChange(value: string) {
    let registro = ''

    registro = value.replace(/[^\d]+/g, '')

    if (!registro) {
      setRegister('')
      return
    } else {
      setRegister(registro)
      return
    }
  }

  return (
    <div className="new__producer__modal">
      <div className="new__producer__modal__box">
        <div className="header">
          <h1>CADASTRAR PRODUTOR</h1>

          <img src={close} alt="close" onClick={() => closeModal(false)} />
        </div>

        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="input__box">
            <input type="text" name="nome" placeholder="NOME" required onChange={(e) => setName(e.target.value)} />
            <img src={userIcon} alt="hidden-password" />
          </div>

          <div className="input__box">
            <ReactInputMask
              mask={register ? (register.length > 11 ? '99.999.999/9999-99' : '999.999.999-999') : '999.999.999-999'}
              maskPlaceholder={null}
              alwaysShowMask={false}
              placeholder="CPF/CNPJ"
              type="text"
              required
              name="CPF"
              onChange={(e: any) => handleRegisterChange(e.target.value)}
            />
            <img src={infoIcon} alt="hidden-password" />
          </div>

          <Toast visible={toastVisible} type={toastType} title={toastMessage} />

          <div className="buttons">
            <button
              disabled={waitingRequest}
              className={`confirm ${waitingRequest ? 'button__loading' : ''}`}
              type="submit"
            >
              <span>CADASTRAR</span>
            </button>
            <button className="cancel" onClick={() => closeModal(false)}>
              <span>CANCELAR</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
