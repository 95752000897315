import { createContext, useEffect } from 'react'
import { apiAuth } from '../services/apiAuth'

import { verifyToken } from '../utils/verify-token'
import { logout } from '../utils/logout'

import Cookies from 'js-cookie'
import { error } from '../utils/error'

interface AuthPayloadType {
  id: number
  id_empesa: number
  empresa: string
  cpf_cnpj: string
  token: string
  verificado: boolean
  configurado: boolean
  admin: boolean
}

interface AuthContextType {
  signIn: (data: SignInData) => Promise<null | false | AuthPayloadType>
  getToken: () => string | undefined
}

interface SignInData {
  user: string
  password: string
}

export const AuthContext = createContext({} as AuthContextType)

export function AuthProvider({ children }: any) {
  const unrequiredTokenPages = [
    '/',
    '/signup',
    '/signup/confirm',
    '/forgot-password',
    '/account-created',
    '/recover-password',
    '/recovered-password',
    '/email-sended',
    '/unverified',
  ]

  useEffect(() => {
    const token = Cookies.get('@SILO:token')

    if (token) {
      authorize(token)
    } else {
      const pathname = window.location.pathname
      if (!unrequiredTokenPages.includes(pathname)) {
        window.location.href = '/'
      }
    }
  }, [])

  function getToken() {
    return Cookies.get('@SILO:token')
  }

  async function authorize(token: string) {
    const tokenIsValid = await verifyToken(token)

    if (!tokenIsValid) {
      logout()
    } else {
      if (window.location.pathname === '/') {
        window.location.href = '/dashboard'
      }
    }
  }

  async function signIn({ user, password }: SignInData) {
    try {
      const returnSignIn = await apiAuth
        .post('/usuarios/auth', {
          usuario: user,
          senha: password,
        })
        .then((response) => {
          return response.data
        })
        .catch((uncaught) => {
          if (uncaught.response.status === 401) {
            return false
          }

          return null
        })

      if (returnSignIn === null) {
        return null
      } else if (returnSignIn === false) {
        return false
      }

      if (returnSignIn.verificado) {
        Cookies.set('@SILO:token', returnSignIn.token, { expires: 1 })
        Cookies.set('@SILO:company', returnSignIn.empresa)
        Cookies.set('@SILO:id', returnSignIn.id.toString())
        Cookies.set('@SILO:companyId', returnSignIn.id_empresa.toString())
        Cookies.set('@SILO:configured', returnSignIn.configurado.toString())
      }

      Cookies.set('@SILO:verified', returnSignIn.verificado.toString())

      return returnSignIn
    } catch (err) {
      error('fail on login', err)
    }
  }

  return <AuthContext.Provider value={{ signIn, getToken }}>{children}</AuthContext.Provider>
}
