import './styles.css'

interface SubheaderProps {
  title: string
  icon: string
}

export default function Subheader({ title, icon }: SubheaderProps) {
  return (
    <div className="subheader">
      <div className="alignment">
        <img src={require(`../../assets/icons/sidebar/${icon}.svg`)} alt="" />
        <h1>{title}</h1>
      </div>
    </div>
  )
}
