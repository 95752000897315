import { apiAuth } from '../services/apiAuth'

export const verifyToken = async (token: string): Promise<boolean> => {
  return await apiAuth
    .get(`/usuarios/verificar_token/${token}`)
    .then((response) => {
      return response.data.valid
    })
    .catch((err) => {
      return null
    })
}
