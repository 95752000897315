import { logout } from '../utils/logout'

import axios from 'axios'
import Cookies from 'js-cookie'
import variables from '../variables'

const token = Cookies.get('@SILO:token')

export const api = axios.create({
  baseURL: variables.SILO_API_URL,
})

api.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      logout()
    }

    return response
  },
  (error) => {
    return Promise.reject(error)
  }
)

if (token) {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`
  api.defaults.headers.post['Content-Type'] = 'application/json'
}
