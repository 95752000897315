import { useContext, useState } from 'react'
import { Modal } from '@mui/material'
import { SidebarContext } from '../../contexts/SidebarContext'
import { logout } from '../../utils/logout'

import ChangeAppPassword from './components/ChangeAppPassword'
import Cookies from 'js-cookie'
import soloVirtualBranca from '../../assets/images/silo-virtual-branca.png'
import iconeSiloVirtualBranco from '../../assets/images/silo-virtual-icone-branco.png'
import gear from '../../assets/icons/gear.svg'
import gearWithAlert from '../../assets/icons/gear-alert.svg'
import exit from '../../assets/icons/exit.svg'
import menu from '../../assets/icons/menu.svg'

import './styles.css'

export default function Header() {
  const { handleOpen } = useContext(SidebarContext)
  const configured = Cookies.get('@SILO:configured')
  const [changeAppPasswordModalOpened, setChangeAppPasswordModalOpened] = useState(false)

  return (
    <header>
      <div className="container">
        <div className="left__side">
          <div className="menu" onClick={() => handleOpen()}>
            <img src={menu} alt="menu" />
          </div>

          <img src={soloVirtualBranca} alt="silo-virtual-branca" className="md-logo" />
          <img src={iconeSiloVirtualBranco} alt="silo-virtual-icone-branco" className="sm-logo" />
        </div>

        <div className="buttons">
          {configured === 'true' ? (
            <img src={gear} alt="configs" onClick={() => setChangeAppPasswordModalOpened(true)} />
          ) : (
            <img src={gearWithAlert} alt="configs" onClick={() => setChangeAppPasswordModalOpened(true)} />
          )}

          <button className="md-exit-button" onClick={() => logout()}>
            SAIR
          </button>

          <img className="sm-exit-button" src={exit} alt="logoff" onClick={() => logout()} />
        </div>

        <Modal
          onClose={setChangeAppPasswordModalOpened}
          aria-labelledby="change-app-password"
          open={changeAppPasswordModalOpened}
        >
          <ChangeAppPassword closeModal={setChangeAppPasswordModalOpened} />
        </Modal>
      </div>
    </header>
  )
}
