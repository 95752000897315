import { useEffect, useState } from 'react'

import { apiAuth } from '../../services/apiAuth'
import { error } from '../../utils/error'

import Cookies from 'js-cookie'

import Header from '../../components/Header'
import Sidebar from '../../components/Sidebar'

import emptyFilter from '../../assets/icons/empty-filter.svg'
import Subheader from '../../components/Subheader'

import './styles.css'

interface User {
  id: number
  email: string
  liberado: number
}

export interface Pending {
  cod_produtor: number
  pendentes: number
}

export default function EmployeesAccounts() {
  const companyId = Cookies.get('@SILO:companyId')

  const [companyUsers, setCompanyUsers] = useState<User[]>([])

  useEffect(() => {
    getCompanyUsers()
  }, [])

  async function getCompanyUsers() {
    try {
      const { data } = await apiAuth.get(`/usuarios/empresa/${companyId}`)
      setCompanyUsers(data)
    } catch (err) {
      error('fail on load company users', { err })
    }
  }

  async function handleUserLiberation(userId: number, liberate: boolean) {
    try {
      await apiAuth.patch(`/usuarios/${userId}/${liberate ? 'liberar' : 'bloquear'}`)
      getCompanyUsers()
    } catch (err) {
      error('fail on liberate company user', { err })
    }
  }

  return (
    <div className="employees__accounts">
      <Header />
      <Subheader title="USUÁRIOS DE COLABORADORES" icon="employees" />

      <div className="content">
        <Sidebar />
        <div className="container">
          <div className="table__box">
            <div className="header">
              <h1>ACESSO AO ESTEIRAS</h1>
            </div>

            {companyUsers.length ? (
              <div className="overflow">
                <table>
                  <thead>
                    <tr>
                      <td>E-MAIL</td>
                      <td>STATUS</td>
                      <td align="right">LIBERAR</td>
                    </tr>
                  </thead>

                  <tbody>
                    {companyUsers.map((user) => (
                      <tr key={user.email}>
                        <td>{user.email}</td>
                        <td>{user.liberado ? 'Liberado' : 'Pendente'}</td>
                        <td align="right">
                          <label className="switch">
                            <input
                              type="checkbox"
                              defaultChecked={!!user.liberado}
                              onChange={(e) => handleUserLiberation(user.id, e.target.checked)}
                            />
                            <span className="slider round"></span>
                          </label>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="empty__table">
                <img src={emptyFilter} alt="empty-filter" />

                <h5>NÃO EXISTEM USUÁRIOS PARA SEREM LISTADOS</h5>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
