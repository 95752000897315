import { useState, useContext } from 'react'
import { SidebarContext } from '../../contexts/SidebarContext'

import arrowDown from '../../assets/icons/sidebar/arrow-down.svg'
import home from '../../assets/icons/sidebar/home.svg'
import warehouse from '../../assets/icons/sidebar/warehouse.svg'
import storageReport from '../../assets/icons/sidebar/storage-report.svg'
import token from '../../assets/icons/sidebar/token.svg'
import output from '../../assets/icons/sidebar/output.svg'
import packingList from '../../assets/icons/sidebar/packing-list.svg'
import summary from '../../assets/icons/sidebar/summary.svg'
import lock from '../../assets/icons/sidebar/lock.svg'
import clients from '../../assets/icons/sidebar/clients.svg'
import employees from '../../assets/icons/sidebar/employees.svg'
import fire from '../../assets/icons/sidebar/fire.svg'

import './styles.css'

export default function Sidebar() {
  const { opened } = useContext(SidebarContext)

  const [openedMenuId, setOpenedMenuId] = useState<string | null>(null)

  function handleMenuClick(elementId: string, redirect?: string) {
    if (redirect) {
      window.location.href = redirect
    } else {
      const previousSelectedMenu = openedMenuId

      if (previousSelectedMenu) {
        const previousSelectedElement = document.getElementById(previousSelectedMenu)
        previousSelectedElement?.classList.remove('selected')

        setOpenedMenuId(null)
      } else {
        setOpenedMenuId(elementId)
        const selectedElement = document.getElementById(elementId)
        selectedElement?.classList.add('selected')
      }
    }
  }

  return (
    <aside className={opened ? 'opened' : 'closed'}>
      <div className="sidebar__header">
        <div className="user__info">
          <h1>Luan Hubner</h1>
          <h5>Administrador</h5>
        </div>
      </div>

      <div className="links">
        <div className="list__group">
          <div className="link" onClick={() => handleMenuClick('_', 'dashboard')}>
            <div className="title">
              <img src={home} alt="home" />
              <span>Home</span>
            </div>
          </div>
        </div>

        <div className="list__group">
          <div className="link" id="warehouse" onClick={() => handleMenuClick('warehouse')}>
            <div className="title">
              <img src={warehouse} alt="warehouse" />
              <span>Armazém</span>
            </div>
            <img src={arrowDown} alt="arrow-down" className={openedMenuId === 'warehouse' ? 'opened' : ''} />
          </div>

          <div className={`sublinks ${openedMenuId === 'warehouse' ? 'opened' : ''}`}>
            <div className="title">
              <img src={storageReport} alt="storage-report" />
              <span>Boletim Depósito</span>
            </div>

            <div className="title">
              <img src={token} alt="token" />
              <span>Ficha Física</span>
            </div>

            <div className="title">
              <img src={output} alt="output" />
              <span>Lotes de Saída</span>
            </div>

            <div className="title">
              <img src={packingList} alt="packing-list" />
              <span>Romaneios</span>
            </div>

            <div className="title">
              <img src={summary} alt="summary" />
              <span>Resumo da Safra</span>
            </div>

            <div className="title" onClick={() => handleMenuClick('_', 'dryer')}>
              <img src={fire} alt="fire" />
              <span>Secagem</span>
            </div>
          </div>
        </div>

        <div className="list__group" onClick={() => handleMenuClick('lock')}>
          <div className="link" id="lock">
            <div className="title">
              <img src={lock} alt="lock" />
              <span>Controle de Acessos</span>
            </div>
            <img src={arrowDown} alt="arrow-down" className={openedMenuId === 'lock' ? 'opened' : ''} />
          </div>

          <div className={`sublinks ${openedMenuId === 'lock' ? 'opened' : ''}`}>
            <div className="title" onClick={() => handleMenuClick('_', 'devices')}>
              <img src={clients} alt="clients" />
              <span>Clientes</span>
            </div>

            <div className="title" onClick={() => handleMenuClick('_', 'employees-accounts')}>
              <img src={employees} alt="employees" />
              <span>Colaboradores</span>
            </div>
          </div>
        </div>
      </div>
    </aside>
  )
}
