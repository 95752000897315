import { BaseSyntheticEvent, useState } from 'react'
import { apiAuth } from '../../../../services/apiAuth'

import Cookies from 'js-cookie'
import close from '../../../../assets/icons/close.svg'
import eye from '../../../../assets/icons/eye.svg'
import hiddenEye from '../../../../assets/icons/hidden-eye.svg'

import Toast from '../../../Toast'

import './styles.css'

interface ChangeAppPasswordModalProps {
  closeModal: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ChangeAppPassword({ closeModal }: ChangeAppPasswordModalProps) {
  const configured = Cookies.get('@SILO:configured')

  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false)

  const [waitingRequest, setWaitingRequest] = useState(false)
  const [toastType, setToastType] = useState<'error' | 'alert' | 'success'>('alert')
  const [toastVisible, setToastVisible] = useState<boolean>(false)
  const [toastMessage, setToastMessage] = useState<string>('')

  function validateFields() {
    if (password !== passwordConfirmation) {
      setToastType('alert')
      setToastMessage('SENHAS DIFERENTES')
      setToastVisible(true)
      setWaitingRequest(false)

      setTimeout(() => {
        setToastVisible(false)
      }, 5000)

      return false
    } else {
      return true
    }
  }

  function handleToast(type: 'error' | 'alert' | 'success', visible: boolean, message: string) {
    setToastType(type)
    setToastMessage(message)
    setToastVisible(visible)

    setTimeout(() => {
      setToastVisible(false)
    }, 5000)
  }

  async function handleSubmit(e: BaseSyntheticEvent) {
    e.preventDefault()
    setWaitingRequest(true)

    const equalPasswords = validateFields()

    if (equalPasswords) {
      const response = await apiAuth
        .patch('/usuarios/senha/app', { senha: password })
        .finally(() => setWaitingRequest(false))

      if (response.status !== 200) {
        handleToast('error', true, response.data.error)
        return
      }

      handleToast('success', true, 'SENHA ALTERADA')

      Cookies.remove('@SILO:configured')
      Cookies.set('@SILO:configured', 'true')

      setTimeout(() => {
        closeModal(false)
      }, 3000)
    }
  }

  return (
    <div className="change__app__password__modal">
      <div className="change__app__password__box">
        <div className="header">
          <h1>{configured === 'false' ? 'CADASTRAR' : 'ALTERAR'} SENHA DO APP</h1>

          <img src={close} alt="close" onClick={() => closeModal(false)} />
        </div>

        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="input__box">
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder="SENHA"
              required
              onChange={(e) => setPassword(e.target.value)}
            />
            {showPassword ? (
              <img src={hiddenEye} alt="hidden-password" onClick={() => setShowPassword(false)} />
            ) : (
              <img src={eye} alt="show-password" onClick={() => setShowPassword(true)} />
            )}
          </div>

          <div className="input__box">
            <input
              type={showPasswordConfirmation ? 'text' : 'password'}
              placeholder="CONFIRMAR SENHA"
              required
              onChange={(e) => setPasswordConfirmation(e.target.value)}
            />
            {showPasswordConfirmation ? (
              <img src={hiddenEye} alt="hidden-password" onClick={() => setShowPasswordConfirmation(false)} />
            ) : (
              <img src={eye} alt="show-password" onClick={() => setShowPasswordConfirmation(true)} />
            )}
          </div>

          <Toast visible={toastVisible} type={toastType} title={toastMessage} />

          <div className="buttons">
            <button className={`confirm ${waitingRequest ? 'button__loading' : ''}`} type="submit">
              <span>CONFIRMAR</span>
            </button>
            <button className="cancel" onClick={() => closeModal(false)}>
              <span>CANCELAR</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
