import { useState } from 'react'

import './styles.css'

import { Modal, Pagination, Tooltip } from '@mui/material'
import { apiEsteiras } from '../../../services/apiEsteiras'
import { today } from '../../../utils/common'
import { Classification } from '../../../interfaces'
import { error } from '../../../utils/error'

import Header from '../../../components/Header'
import Sidebar from '../../../components/Sidebar'
import Subheader from '../../../components/Subheader'
import GraphicBox from './components/GraphicBox'
import TableRow from '../../../components/TableRow'
import NumbersBox from './components/NumbersBox'

import filterIcon from '../../../assets/icons/filter.svg'
import tableIcon from '../../../assets/icons/table.svg'
import alertIcon from '../../../assets/icons/alert-yellow.svg'

import FilterClassificationsModal from '../../../components/FilterClassificationsModal'
import InitialClassificationsFilterParamsModal from '../../../components/InitialClassificationsFilterParamsModal'

interface ClassificationsSummary {
  entradas: number
  media_temperatura_entrada: number
  media_temperatura_saida: number
  media_umidade_entrada: number
  media_umidade_saida: number
  saidas: number
  ultima_entrada: Classification
  ultima_saida: Classification
}

export interface FilterProps {
  productId: number
  harvestId: number
  siloId: number
  startDate: string
  finishDate: string
}

export default function Dryer() {
  const COMPONENTS_PER_PAGE = 10

  const [filterClassificationsModalOpen, setFilterClassificationsModalOpen] = useState(false)
  const [initialClassificationsFilterParamsModalOpen, setInitialClassificationsFilterParamsModalOpen] = useState(true)

  const [pages, setPages] = useState<number>(0)
  const [pageData, setPageData] = useState<Classification[] | null>([])
  const [summary, setSummary] = useState({} as ClassificationsSummary)
  const [filteringDates, setFilteringDates] = useState<string>('')

  const [inputClassifications, setInputClassifications] = useState<Classification[]>([])
  const [outputClassifications, setOutputClassifications] = useState<Classification[]>([])

  const [loadingSummary, setLoadingSummary] = useState(true)

  const [tableClassifications, setTableClassifications] = useState<Classification[]>([])

  async function handleFilter(props: FilterProps) {
    setFilteringDates(`${props.startDate} - ${props.finishDate}`)
    filter(props)
    loadCompanyClassificationsSummary(props)
  }

  async function handleTableList(type: 'input' | 'output') {
    const data = type === 'input' ? inputClassifications : outputClassifications
    setTableClassifications(data)

    const dataSplited = String(data && data.length / COMPONENTS_PER_PAGE).split('.')

    if (dataSplited[1]) {
      setPages(Number(dataSplited[0]) + 1)
    } else {
      setPages(Number(dataSplited[0]))
    }

    setPageData(data.slice(0, COMPONENTS_PER_PAGE))
  }

  async function filter(props: FilterProps) {
    try {
      const { data } = await apiEsteiras.get('/classificacoes/filtrar', {
        params: {
          id_silo: props.siloId,
          id_safra: props.harvestId,
          id_produto: props.productId,
          data_inicial: props.startDate,
          data_final: props.finishDate,
        },
      })
      setInputClassifications(data.filter((c: Classification) => c.tipo === 1))
      setOutputClassifications(data.filter((c: Classification) => c.tipo === 2))
    } catch (err) {
      error('fail on classifications filtering', { err })
    }
  }

  function handlePaginationChange(event: any, value: number) {
    const startIndex = value * COMPONENTS_PER_PAGE - COMPONENTS_PER_PAGE
    const lastIndex = startIndex + COMPONENTS_PER_PAGE

    if (tableClassifications.length) {
      setPageData(tableClassifications.slice(startIndex, lastIndex))
    }
  }

  async function loadCompanyClassificationsSummary(props: FilterProps) {
    try {
      const { data } = await apiEsteiras.get(`/classificacoes/resumo`, {
        params: {
          data_inicial: props.startDate ? props.startDate : today,
          data_final: props.finishDate ? props.finishDate : today,
          id_silo: props.siloId,
          id_safra: props.harvestId,
          id_produto: props.productId,
        },
      })
      setSummary(data)
      setLoadingSummary(false)
    } catch (err) {
      error('fail on load company classifications summary', { err })
    }
  }

  return (
    <div className="dryer">
      <Header />
      <Subheader title="SECAGEM" icon="fire" />

      <div className="content">
        <Sidebar />

        <div className="container">
          <div className="stats__box">
            {!summary.entradas && !loadingSummary ? <img src={alertIcon} alt="alert" className="alert__icon" /> : null}

            <div className="info__box__header">
              <h1>ENTRADAS</h1>

              <Tooltip title="Filtrar" placement="top">
                <div className="filter__button" onClick={() => setFilterClassificationsModalOpen(true)}>
                  <img src={filterIcon} alt="filter" />
                </div>
              </Tooltip>

              <Tooltip title="Listar na Tabela" placement="top">
                <div className="filter__button" onClick={() => handleTableList('input')}>
                  <img src={tableIcon} alt="tablement" />
                </div>
              </Tooltip>
            </div>

            {loadingSummary ? (
              <div className="loading__box loading">
                <span></span>
              </div>
            ) : !summary.entradas ? (
              <h1 className="alert">
                NENHUMA CLASSIFICAÇÃO DE ENTRADA CADASTRADA <br /> <b>({filteringDates})</b>
              </h1>
            ) : (
              <>
                <div className="info__box">
                  <div className="info__box__content">
                    <NumbersBox
                      badgeColor="#449B67"
                      badgeIcon="input"
                      info={summary.entradas}
                      title="ENTRADAS TOTAIS"
                    />
                    <NumbersBox
                      badgeColor="#FF7A30"
                      badgeIcon="temperature"
                      info={`${summary.media_temperatura_entrada}ºC`}
                      title="TEMPERATURA MÉDIA"
                    />
                    <NumbersBox
                      badgeColor="#0092E4"
                      badgeIcon="humidity"
                      info={`${summary.media_umidade_entrada}%`}
                      title="UMIDADE MÉDIA"
                    />
                  </div>
                </div>
                <div className="graphic">
                  <GraphicBox data={inputClassifications} />
                </div>
              </>
            )}
          </div>

          <div className="stats__box">
            {!summary.saidas && !loadingSummary ? <img src={alertIcon} alt="alert" className="alert__icon" /> : null}

            <div className="info__box__header">
              <h1>SAÍDAS</h1>

              <Tooltip title="Filtrar" placement="top">
                <div className="filter__button" onClick={() => setFilterClassificationsModalOpen(true)}>
                  <img src={filterIcon} alt="filter" />
                </div>
              </Tooltip>

              <Tooltip title="Listar na Tabela" placement="top">
                <div className="filter__button" onClick={() => handleTableList('output')}>
                  <img src={tableIcon} alt="tablement" />
                </div>
              </Tooltip>
            </div>

            {loadingSummary ? (
              <div className="loading__box loading">
                <span></span>
              </div>
            ) : !summary.saidas ? (
              <h1 className="alert">
                NENHUMA CLASSIFICAÇÃO DE SAÍDA CADASTRADA <br /> <b>({filteringDates})</b>
              </h1>
            ) : (
              <>
                <div className="info__box">
                  <div className="info__box__content">
                    <NumbersBox badgeColor="#449B67" badgeIcon="input" info={summary.saidas} title="SAÍDAS TOTAIS" />
                    <NumbersBox
                      badgeColor="#FF7A30"
                      badgeIcon="temperature"
                      info={`${summary.media_temperatura_saida}ºC`}
                      title="TEMPERATURA MÉDIA"
                    />
                    <NumbersBox
                      badgeColor="#0092E4"
                      badgeIcon="humidity"
                      info={`${summary.media_umidade_saida}%`}
                      title="UMIDADE MÉDIA"
                    />
                  </div>
                </div>
                <div className="graphic">
                  <GraphicBox data={outputClassifications} />
                </div>
              </>
            )}
          </div>

          {pageData?.length ? (
            <div className="stats__box">
              <table id="table">
                <thead>
                  <tr>
                    <th align="left">Data/Hora</th>
                    <th align="left">Temperatura</th>
                    <th align="left">Umidade</th>
                    <th align="left">Silo</th>
                    <th align="left">Produto</th>
                    <th align="left">Safra</th>
                    <th align="left">Usuário</th>
                  </tr>
                </thead>

                <tbody>
                  {pageData?.map((item) => (
                    <TableRow key={item.id} item={item} />
                  ))}
                </tbody>
              </table>

              <div className="table__footer">
                {tableClassifications.length > 5 ? (
                  <Pagination
                    count={pages}
                    color="standard"
                    variant="outlined"
                    shape="rounded"
                    onChange={handlePaginationChange}
                  />
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <Modal
        onClose={setFilterClassificationsModalOpen}
        aria-labelledby="change-app-password"
        open={filterClassificationsModalOpen}
      >
        <FilterClassificationsModal
          filter={handleFilter}
          setFilterClassificationsModalOpen={setFilterClassificationsModalOpen}
        />
      </Modal>

      <Modal
        onClose={setInitialClassificationsFilterParamsModalOpen}
        aria-labelledby="change-app-password"
        open={initialClassificationsFilterParamsModalOpen}
      >
        <InitialClassificationsFilterParamsModal
          filter={handleFilter}
          setInitialClassificationsFilterParamsModalOpen={setInitialClassificationsFilterParamsModalOpen}
        />
      </Modal>
    </div>
  )
}
