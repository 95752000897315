import alert from '../../assets/icons/alert-white.svg'
import info from '../../assets/icons/info-white.svg'
import success from '../../assets/icons/checked-white.svg'

import './styles.css'

interface ToastProps {
  visible: boolean
  type: 'error' | 'alert' | 'success'
  title: string
}

export default function Toast({ visible, type, title }: ToastProps) {
  return visible ? (
    <div className={`toast ${type}`}>
      <div className="icon">
        {type === 'error' && <img src={alert} alt="error-icon" />}
        {type === 'alert' && <img src={info} alt="alert-icon" />}
        {type === 'success' && <img src={success} alt="success-icon" color="#fff" />}
      </div>

      <div className="title">
        <h5>{title}</h5>
      </div>
    </div>
  ) : null
}
