import siloVirtualImage from '../../assets/images/silo-virtual.png'
import checkedIcon from '../../assets/icons/checked.svg'
import arrowLeftIcon from '../../assets/icons/arrow-left.svg'

import './styles.css'

export default function AccountCreated() {
  return (
    <div className="account__created">
      <div className="container">
        <img src={siloVirtualImage} alt="silo-virtual" className="logo__app" />

        <div className="confirmed">
          <img src={checkedIcon} alt="check-icon" />

          <h5>CADASTRO EFETUADO</h5>
        </div>

        <h1>ENVIAMOS UM E-MAIL DE CONFIRMAÇÃO PARA VOCÊ</h1>

        <a href="/">
          <img src={arrowLeftIcon} alt="arrow-left" />
          VOLTAR PARA O LOGIN
        </a>
      </div>
    </div>
  )
}
