import { useContext, useEffect, useState } from 'react'

import './styles.css'

import { TotalizersContext } from '../../contexts/TotalizersContext'
import { apiAuth } from '../../services/apiAuth'
import { Modal } from '@mui/material'

import Cookies from 'js-cookie'

import Header from '../../components/Header'
import ConnectedDevices from '../../components/ConnectedDevices'
import CreateNewProducer from '../../components/CreateNewProducer'
import Sidebar from '../../components/Sidebar'

import userCheck from '../../assets/icons/user-check.svg'
import lockWhite from '../../assets/icons/lock-white.svg'
import shield from '../../assets/icons/shield.svg'
import userClock from '../../assets/icons/user-clock.svg'
import search from '../../assets/icons/search.svg'
import emptyFilter from '../../assets/icons/empty-filter.svg'
import Subheader from '../../components/Subheader'

interface Client {
  ID: number
  NOME: string
  REGISTRO: string
  LIBERADO: number
  COD_PRODUTOR: number
}

export interface Pending {
  cod_produtor: number
  pendentes: number
}

export default function Devices() {
  const company = Cookies.get('@SILO:company')
  const companyId = Cookies.get('@SILO:companyId')
  const [clients, setClients] = useState<Client[]>([])
  const [filteredClients, setFilteredClients] = useState<Client[]>(clients)
  const [modalConnectedDevicesOpened, setModalConnectedDevicesOpened] = useState(false)
  const [modalNewProducerOpened, setModalNewProducerOpened] = useState(false)
  const [clientToShowDevices, setClientToShowDevices] = useState<Client>()

  const [totalClientsLiberated, setTotalClientsLiberated] = useState(0)

  const { pendings, totalActives, totalPendings, totalNewAccesses } = useContext(TotalizersContext)

  useEffect(() => {
    getClients()
  }, [])

  async function getClients() {
    await apiAuth
      .get(`/produtores/armazem/${companyId}`)
      .then((response) => {
        let total = 0
        response.data.map((client: { LIBERADO: number }) => {
          client.LIBERADO && total++
        })
        setTotalClientsLiberated(total)
        setClients(response.data)
        setFilteredClients(response.data)
      })
      .catch((uncaught) => {
        return
      })
  }

  function handleSearch(value: string) {
    if (!value) {
      setFilteredClients(clients)
    } else {
      let filtered: Client[] = []

      for (const client of clients) {
        if (client.NOME.toLowerCase().includes(value.toLowerCase())) {
          filtered.push(client)
        }

        if (client.REGISTRO.includes(value)) {
          filtered.push(client)
        }
      }

      setFilteredClients(filtered)
    }
  }

  return (
    <div className="devices">
      <Header />
      <Subheader title="USUÁRIOS DE CLIENTES" icon="clients" />

      <div className="content">
        <Sidebar />
        <div className="container">
          <div className="info">
            <div className="stats">
              <div className="top__bar">
                <h4>Usuários Ativos</h4>
              </div>

              <div className="bottom__bar">
                <h2>{totalActives}</h2>

                <img src={userCheck} alt="user-check" />
              </div>
            </div>

            <div className="stats">
              <div className="top__bar">
                <h4>Novos Acessos</h4>
              </div>

              <div className="bottom__bar">
                <h2>{totalNewAccesses}</h2>

                <img src={shield} alt="user-check" />
              </div>
            </div>

            <div className="stats">
              <div className="top__bar">
                <h4>Clientes Liberados</h4>
              </div>

              <div className="bottom__bar">
                <h2>{totalClientsLiberated}</h2>

                <img src={lockWhite} alt="user-check" />
              </div>
            </div>

            <div className="stats">
              <div className="top__bar">
                <h4>Usuários Pendentes</h4>
              </div>

              <div className="bottom__bar">
                <h2>{totalPendings}</h2>

                <img src={userClock} alt="user-check" />
              </div>
            </div>
          </div>

          <div className="table__box">
            <div className="header">
              <h1>ACESSO AO APLICATIVO</h1>

              <div className="options">
                {/* <button onClick={() => setModalNewProducerOpened(true)} title="Cadastrar Produtor">
                <img src={plus} alt="add-icon" />
              </button> */}

                <div className="input__box">
                  <img src={search} alt="pesquisar" />
                  <input type="text" placeholder="Pesquisar..." onChange={(e) => handleSearch(e.target.value)} />
                </div>
              </div>
            </div>

            {filteredClients.length ? (
              <div className="overflow">
                <table>
                  <thead>
                    <tr>
                      <td>CLIENTE</td>
                      <td>CPF/CNPJ</td>
                      <td>STATUS</td>
                      <td>PENDENTES</td>
                    </tr>
                  </thead>

                  <tbody>
                    {filteredClients.map((client) => (
                      <tr
                        key={client.NOME}
                        onClick={() => {
                          setClientToShowDevices(client)
                          setModalConnectedDevicesOpened(true)
                        }}
                      >
                        <td>{client.NOME}</td>
                        <td>{client.REGISTRO}</td>
                        <td>{client.LIBERADO === 1 ? 'Liberado' : 'Pendente'}</td>
                        <td>
                          {pendings.filter((pending) => pending.cod_produtor === client.COD_PRODUTOR)[0]?.pendentes ||
                            0}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="empty__table">
                <img src={emptyFilter} alt="empty-filter" />

                <h5>NÃO EXISTEM CLIENTES PARA SEREM LISTADOS</h5>
              </div>
            )}

            <Modal
              onClose={setModalConnectedDevicesOpened}
              aria-labelledby="connected-devices"
              open={modalConnectedDevicesOpened}
            >
              <ConnectedDevices
                client={clientToShowDevices}
                getClients={getClients}
                closeModal={setModalConnectedDevicesOpened}
              />
            </Modal>

            <Modal onClose={setModalNewProducerOpened} aria-labelledby="new-producer" open={modalNewProducerOpened}>
              <CreateNewProducer closeModal={setModalNewProducerOpened} getClients={getClients} />
            </Modal>
          </div>
        </div>
      </div>
    </div>
  )
}
