import { useContext, useState } from 'react'
import { AuthContext } from './contexts/AuthContext'

import './App.css'

import siloVirtualImage from './assets/images/silo-virtual.png'

import userIcon from './assets/icons/user.svg'
import lockIcon from './assets/icons/lock.svg'
import Toast from './components/Toast'

export default function App() {
  const [toastType, setToastType] = useState<'error' | 'alert'>('error')
  const [toastVisible, setToastVisible] = useState<boolean>(false)
  const [toastMessage, setToastMessage] = useState<string>('')

  const [user, setUser] = useState('')
  const [password, setPassword] = useState('')

  const [waitingRequest, setWaitingRequest] = useState(false)

  const { signIn } = useContext(AuthContext)

  function handleSubmit(e: any) {
    e.preventDefault()
    setWaitingRequest(true)
    login()
  }

  async function login() {
    const returnSignIn = await signIn({ user, password }).finally(() => setWaitingRequest(false))

    if (returnSignIn === null) {
      setToastType('error')
      setToastMessage('FALHA NA REQUISIÇÃO')
      setToastVisible(true)
      setTimeout(() => {
        setToastVisible(false)
      }, 5000)
      return
    }

    if (returnSignIn === false || !returnSignIn.admin) {
      setToastType('error')
      setToastMessage('CREDENCIAIS INCORRETAS')
      setToastVisible(true)
      setTimeout(() => {
        setToastVisible(false)
      }, 5000)
      return
    }

    if (!returnSignIn.verificado) {
      window.localStorage.setItem('@EMAIL', user)
      window.location.href = '/unverified'
      return
    }

    window.location.href = '/dashboard'
  }

  return (
    <div className="App">
      <form onSubmit={(e) => handleSubmit(e)}>
        <img src={siloVirtualImage} alt="silo-virtual" className="logo__app" />

        <div className="input__box">
          <input type="email" name="email" required placeholder="E-MAIL" onChange={(e) => setUser(e.target.value)} />
          <img src={userIcon} alt="" />
        </div>

        <div className="input__box">
          <input type="password" required placeholder="SENHA" onChange={(e) => setPassword(e.target.value)} />
          <img src={lockIcon} alt="" />
        </div>

        <Toast visible={toastVisible} type={toastType} title={toastMessage} />

        <button disabled={waitingRequest} className={waitingRequest ? 'button__loading' : ''} type="submit">
          <span>ENTRAR</span>
        </button>

        <a href="/forgot-password">ESQUECI MINHA SENHA</a>

        <hr />

        <div className="create__account">
          <h5>AINDA NÃO POSSUI UMA CONTA?</h5>
          <a href="/signup">CADASTRE-SE</a>
        </div>
      </form>
    </div>
  )
}
