import { useContext } from 'react'
import { TotalizersContext } from '../../contexts/TotalizersContext'
import { apiAuth } from '../../services/apiAuth'

import close from '../../assets/icons/close.svg'
import phone from '../../assets/icons/phone.svg'
import mapMarker from '../../assets/icons/map-marker.svg'

import './styles.css'

interface DeviceDetailsModalProps {
  device: any
  getClientDevices: any
  closeModal: any
}

export default function DeviceDetails({ device, getClientDevices, closeModal }: DeviceDetailsModalProps) {
  const parsedDevice = JSON.parse(device.DISPOSITIVO)

  const { getActiveTotalizers } = useContext(TotalizersContext)

  async function allowDevice() {
    await apiAuth.patch(`/dispositivos/${device.ID}/liberar`)
    getClientDevices()
    getActiveTotalizers()
    closeModal()
  }

  async function blockDevice() {
    apiAuth.patch(`/dispositivos/${device.ID}/bloquear`)
    getClientDevices()
    getActiveTotalizers()
    closeModal()
  }

  return (
    <div className="device__details__modal">
      <div className="device__details__box">
        <div className="header">
          <img src={close} alt="close" onClick={() => closeModal(false)} />
        </div>

        <div className="body">
          <div className="info">
            <img src={phone} alt="phone" />

            <h4>{device.DEVICE_NAME}</h4>
          </div>

          <div className="info">
            <img src={mapMarker} alt="map-marker" />

            <h5>{parsedDevice.Endereco}</h5>
          </div>
        </div>

        <div className="buttons">
          <button className={`confirm ${device.LIBERADO && 'disabled'}`} onClick={() => allowDevice()}>
            AUTORIZAR
          </button>
          <button className={`cancel ${!device.LIBERADO && 'disabled'}`} onClick={() => blockDevice()}>
            BLOQUEAR
          </button>
        </div>
      </div>
    </div>
  )
}
