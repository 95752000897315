import './styles.css'

import { Classification } from '../../../../../interfaces'
import { AreaChart, XAxis, YAxis, CartesianGrid, Tooltip, Area } from 'recharts'
import { useEffect, useState } from 'react'

interface NumbersBoxProps {
  data: Classification[]
}

export default function GraphicBox({ data }: NumbersBoxProps) {
  function getWindowDimensions() {
    const { innerWidth: width } = window
    return {
      width,
    }
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div className="graphic__box">
      <AreaChart
        width={windowDimensions.width > 1920 ? 1100 : windowDimensions.width * 0.6}
        height={250}
        data={data}
        style={{ zIndex: 1, marginLeft: -50, paddingTop: 6 }}
      >
        <defs>
          <linearGradient id="colorTemperature" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#d25e06" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#d25e06" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorHumidity" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#276fff" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#276fff" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="hora" allowDataOverflow />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Area type="monotone" dataKey="temperatura" stroke="#8884d8" fillOpacity={1} fill="url(#colorTemperature)" />
        <Area type="monotone" dataKey="umidade" stroke="#82ca9d" fillOpacity={1} fill="url(#colorHumidity)" />
      </AreaChart>

      <div className="stats__box__footer">
        <h3>GRÁFICO</h3>
      </div>
    </div>
  )
}
