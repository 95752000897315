import { createContext } from 'react'
import { Harvest, Product, Silo } from '../interfaces'
import { api } from '../services/api'
import { error } from '../utils/error'

interface CacheContextType {
  loadCache: () => Promise<void>
  loadProductsFromCache: () => Product[]
  loadHarvestsFromCache: () => Harvest[]
  loadSilosFromCache: () => Silo[]
}

export const CacheContext = createContext({} as CacheContextType)

export function CacheProvider({ children }: any) {
  async function loadCache() {
    await getProducts()
    await getHarvests()
    await getSilos()
  }

  async function getProducts() {
    try {
      const { data } = await api.get(`/produtos/empresa`)
      localStorage.setItem('@SILO:products', JSON.stringify(data))
    } catch (err) {
      error('fail on get products', { err })
    }
  }

  async function getSilos() {
    try {
      const { data } = await api.get(`/silos/empresa`)
      localStorage.setItem('@SILO:silos', JSON.stringify(data))
    } catch (err) {
      error('fail on get silos', { err })
    }
  }

  async function getHarvests() {
    try {
      const { data } = await api.get(`/safras/empresa`)
      localStorage.setItem('@SILO:harvests', JSON.stringify(data))
    } catch (err) {
      error('fail on get harvests', { err })
    }
  }

  function loadProductsFromCache(): Product[] {
    const data = localStorage.getItem('@SILO:products')

    if (data) {
      return JSON.parse(data)
    }

    return []
  }

  function loadHarvestsFromCache(): Harvest[] {
    const data = localStorage.getItem('@SILO:harvests')

    if (data) {
      return JSON.parse(data)
    }

    return []
  }

  function loadSilosFromCache(): Silo[] {
    const data = localStorage.getItem('@SILO:silos')

    if (data) {
      return JSON.parse(data)
    }

    return []
  }

  return (
    <CacheContext.Provider value={{ loadProductsFromCache, loadHarvestsFromCache, loadSilosFromCache, loadCache }}>
      {children}
    </CacheContext.Provider>
  )
}
